import {React, useState, useEffect} from "react";
import Footer from "./Footer";
import TopAppBar from "./TopAppBar";
import Hero from "./Hero";
import Main from "./Main";








function Home() {
  const domain = "Glamorous Trend";
  //update partners and footer
  //update index.html title and description
  return (
  <>
    <TopAppBar name={domain} />

    <Hero/>

   <Main domain={domain}/> 

    <Footer />
  </>
  );

}


export default Home;